<template>
  <Page :title="$t('contact.title')" :classes="['page--static', 'page--contact']">
    <p v-html="$t('contact.text')"></p>
    <div class="sha">
      <div
        style="background-image: url(/img/icons/social/twitter-white.svg)"
        :title="$t('common.share_on_$social', {social: 'Twitter'})"
        @click="twitter"></div>
      <div
        style="background-image: url(/img/icons/social/facebook-white.svg)"
        :title="$t('common.share_on_$social', {social: 'Facebook'})"
        @click="facebook"></div>
      <div
        style="background-image: url(/img/icons/social/linkedin-white.svg)"
        :title="$t('common.share_on_$social', {social: 'Linkedin'})"
        @click="linkedin"></div>
    </div>
    <form class="form" @submit="true">

      <InputText
        name="name"
        :label="$t('contact.fields.name')"
        v-model.trim="name"
        :isRequired="true"
      ></InputText>

      <InputEmail
        name="email"
        :label="$t('contact.fields.email')"
        v-model.trim="email"
        :isRequired="true"
      ></InputEmail>

      <InputTextArea
        name="message"
        :label="$t('contact.fields.message')"
        v-model.trim="message"
        :isRequired="true"
      ></InputTextArea>

      <Button
        :isLoading="isLoading"
        :isDisabled="isInvalid"
        :classes="['btn--accent', 'btn--wide']"
        @onclick="submit()">
          <span v-show="!isLoading">{{$t('contact.button.title')}}</span>
          <span v-show="isLoading">{{$t('contact.button.pending')}}</span>
      </Button>
    </form>
  </Page>
</template>

<script>
import Check from '@/services/check/Check';
import UrlService from '@/services/url/UrlService';

const urlService = new UrlService();

export default {
  name: 'ContactPage',
  data() {
    return {
      isLoading: false,
      name: '',
      email: '',
      message: '',
    };
  },
  computed: {
    isInvalid() {
      return !Check.isValidEmail(this.email);
    },
  },
  methods: {
    twitter() {
      urlService.openTwitter();
    },
    facebook() {
      urlService.openFacebook();
    },
    linkedin() {
      urlService.openLinkedIn();
    },
    resetForm() {
      this.name = '';
      this.email = '';
      this.message = '';
    },
    submit() {
      this.isLoading = true;
      if (this.isInvalid) return;

      const data = {
        name: this.name,
        email: this.email,
        message: this.message,
      };

      this.$store.dispatch('post', {
        url: 'contact',
        data,
      }).then(() => {
        this.$store.dispatch('meeseeks', {
          type: 'success',
          message: this.$t('contact.messages.success'),
        });
        this.resetForm();
      }).catch(() => {
        this.$store.dispatch('meeseeks', {
          type: 'error',
          message: this.$t('contact.messages.error'),
        });
      });
      this.isLoading = false;
    },
  },
};
</script>
<style lang="scss" scoped>
p {
  margin-bottom: 0;
}
.sha {
  text-align: center;
  padding: 20px 20px;
  span {
    margin-right: 10px;
  }
  div {
    width: 24px;
    height: 24px;
    margin-right: 10px;
    cursor: pointer;
    transition: opacity 250ms ease;
    background-color: var(--primary-color-default);
    padding: 5px;
    border-radius: 100%;
    display: inline-block;
    vertical-align: middle;
    background-size: 15px 15px;
    background-position: center;
    background-repeat: no-repeat;
    &:hover {
      opacity: 0.6;
    }
  }
}
</style>
